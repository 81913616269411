import $ from "jquery";
import tippy from "tippy.js";

import "./styles.scss";
import "tippy.js/dist/tippy.css";

$(window).on("load", () => {
  // Support email addresses
  $(".e-mail").on("click", (e) => {
    const $el = $(e.target);
    const user = $el.attr("data-user");
    const domain = $el.attr("data-website");
    document.location = `mailto:${user}@${domain}`;
  });

  const instance = tippy(".avatar img", {
    content: "Hey there - I'm Joseph",
    showOnCreate: true,
    hideOnClick: false,
    delay: [250, 500]
  });
});
